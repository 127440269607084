<div class="wo-aboutbannerwrap">
    <div class="wo-aboutbannerbg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 596.99">
            <path class="path1" d="M1923,0V456.68C963,456.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
            <path class="path2" d="M1923,0V436.68C963,436.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="wo-aboutbanner">
                    <div class="wo-aboutbanner__title">
                        <h2>Privacy Policy for Go2 Oneway Taxi</h2>
                    </div>
                    <div class="wo-aboutbanner__description">

                        <div style=" margin:0em 3em;
                        background: white;
                        padding: 16px;
                        border-radius: 5px;">
                            <div>
                                <p><b>WELCOME TO GO2 ONEWAY TAXI !</b></p>

                                <p style="margin-left: 42px;">
                                    GO2 ONEWAY TAXI treats personal information that the Company collects and
                                    receives
                                    very confidentially. Personal information is information about you that is
                                    personally identifiable like your name, address, email address, or phone number,
                                    and
                                    that is not otherwise publicly available. By using this app, you consent to the
                                    collection and use of your information as discussed in this section.<br><br>

                                    This policy does not apply to the practices of companies that the Company does
                                    not
                                    own or control or to people that the Company does not employ or manage.
                                </p>
                            </div>
                            <div class="SSStaxi-cafe-faq">
                                <div><b>I.INFORMATION COLLECTION &amp; USE</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                The Company collects personal information when you provide such
                                                information to the Company, when you visit the Company pages or
                                                the
                                                app of certain of the Company’s partners, and when you enter
                                                promotions or sweepstakes. The Company may combine information
                                                about
                                                you that we have with information we obtain from business
                                                partners
                                                or other companies.
                                            </span>
                                        </li>

                                        <br>
                                        <li>
                                            <span>
                                                The Company may automatically receive and record information
                                                from
                                                your computer and browser, including your IP address, cookie
                                                information, and software and hardware attributes. The company
                                                follows a cookie policy and will be displayed in the app.
                                            </span>
                                        </li>

                                        <br>
                                        <li>
                                            <span>
                                                The Company uses information for the following general purposes:
                                                to
                                                fulfil your requests for products, services and information,
                                                improve
                                                our services, contact you, conduct research, and provide
                                                anonymous
                                                reporting for internal and external clients.
                                            </span>
                                        </li>

                                    </ol>
                                </div>
                            </div>

                            <div class="SSStaxi-cafe-faq">
                                <div><b>II. INFORMATION SHARING AND DISCLOSURE</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>

                                        <li>
                                            <span>
                                                The Company does not rent, sell, or share personal information
                                                about
                                                you with other people or non-affiliated companies except to
                                                provide
                                                products, services or information you’ve requested, when we have
                                                your permission, or under the following circumstances:
                                            </span>
                                        </li>
                                        <br>
                                        <li>
                                            <span>
                                                We provide the information to trusted partners who work on
                                                behalf of
                                                or with the Company under confidentiality agreements. These
                                                companies may use your personal information to help the Company
                                                communicate with you. However, these companies do not have any
                                                independent right to share this information.
                                            </span>
                                        </li><br>
                                        <li>
                                            <span>
                                                We respond to subpoenas, court orders, or legal process (such as
                                                law
                                                enforcement requests), or to establish or exercise our legal
                                                rights
                                                or defend against legal claims.
                                            </span>
                                        </li><br>
                                        <li>
                                            <span>
                                                We believe it is necessary to share information in order to
                                                investigate, prevent, or take action regarding illegal
                                                activities,
                                                suspected fraud, situations involving potential threats to the
                                                physical safety of any person, violations of the Company’s terms
                                                of
                                                use, or as otherwise required by law.
                                            </span>
                                        </li><br>

                                        <li>
                                            <span>
                                                We transfer information about you if the Company is acquired by
                                                or
                                                merged with another company. In this event, the Company will
                                                notify
                                                you before information about you is transferred and becomes
                                                subject
                                                to a different privacy policy.


                                            </span>
                                        </li>


                                    </ol>
                                </div>
                            </div>
                            <div class="SSStaxi-cafe-faq">
                                <div><b>III. COOKIES AND SIMILAR TECHNOLOGIES</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                The Company may set and access Company cookies on your
                                                computer.
                                                We may also set and access device identifiers which could
                                                include IP
                                                address, user agent information (browser version, OS type and
                                                version), and device provided identifiers.
                                            </span>
                                        </li><br>
                                        <li>
                                            <span>
                                                The Company may use web beacons to access Company cookies
                                                inside
                                                and outside our network of web sites and in connection with
                                                Company
                                                products and services.
                                            </span>
                                        </li><br>



                                    </ol>
                                </div>

                            </div>



                            <div class="SSStaxi-cafe-faq">
                                <div><b>IV. CONFIDENTIALITY AND SECURITY</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                We limit access to personal information about you to trusted
                                                partners and contractors who we believe reasonably need to
                                                meetthat
                                                information to provide products or services to you or in order
                                                to do
                                                their jobs.
                                            </span>
                                        </li><br>




                                    </ol>
                                </div>

                            </div>

                            <div class="SSStaxi-cafe-faq">
                                <div><b>V.CHANGES TO THIS PRIVACY POLICY</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                The Company may update this policy. We will notify you about
                                                significant changes in the way we treat personal information by
                                                placing a prominent notice on our site.
                                            </span>
                                        </li><br>

                                    </ol>
                                </div>

                            </div>

                            <div class="SSStaxi-cafe-faq">
                                <div><b>VI.LOCATION </b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                By Registering this mobile Application driver is Accepting to
                                                track his location after he manually click on the "Go-Online" to
                                                get
                                                business benefit from GO2 ONEWAY.
                                            </span>
                                        </li><br>

                                    </ol>
                                </div>

                            </div>
                            <div class="SSStaxi-cafe-faq">
                                <div><b>VII. NO REFUND POLICY</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                We do not offer refunds for our taxi services. Once a booking is
                                                made, no refunds will be provided, regardless of the
                                                circumstances.
                                            </span>
                                        </li><br>

                                    </ol>
                                </div>

                            </div>
                            <div class="SSStaxi-cafe-faq">
                                <div><b>VIII. NO CANCELLATION POLICY</b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                Driver can cancel, after the accept the trip cancellation
                                                charges
                                                will applied
                                            </span>
                                        </li><br>

                                    </ol>
                                </div>

                            </div>
                            <div class="SSStaxi-cafe-faq">
                                <div><b>IX.Delete account </b></div>
                                <div style="margin-left: 42px;">
                                    <ol>
                                        <li>
                                            <span>
                                                1.The following applies to the users of "Go2 Oneway Taxi" app.
                                            </span>
                                        </li><br>
                                        <li>
                                            <span>
                                                If you have a problem with an account, make the call administrator and
                                                ask that it be deleted.Contact No : <b>9047 588 588</b> &amp; E-mail :
                                                go2onewaytaxi.com
                                            </span>
                                        </li>

                                    </ol>
                                </div>

                            </div>
                            <br>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>