// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAhQqSxRrMl__pSTMBzr58qWICjeXiRpOo",
    authDomain: "go2-onewaytaxi.firebaseapp.com",
    projectId: "go2-onewaytaxi",
    storageBucket: "go2-onewaytaxi.appspot.com",
    messagingSenderId: "205173835060",
    appId: "1:205173835060:web:96564e5fa9a90b8fef9161"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
