<div class="wo-aboutbannerwrap">
    <div class="wo-aboutbannerbg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 596.99">
            <path class="path1" d="M1923,0V456.68C963,456.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
            <path class="path2" d="M1923,0V436.68C963,436.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="wo-aboutbanner">
                    <div class="wo-aboutbanner__title">
                        <h2><span>South India's Largest Intercity
                            </span><em> and Local Cab Services</em></h2>
                    </div>
                    <div class="wo-aboutbanner__description">
                        <p>
                            Welcome to Go2 Oneway Taxi, the premier drop taxi service in South India. We are dedicated
                            to providing our customers with safe, reliable, and affordable transportation options.


                        </p>
                        <br>

                        <p>
                            At Go2 Oneway Taxi, we understand that your time is valuable, which is why we offer
                            hassle-free drop taxi services to help you reach your destination quickly and comfortably.
                            Whether you need a ride to the airport, bus station, railway station, or any other location,
                            we are here to serve you.


                        </p>
                        <p>
                            Our team of experienced drivers is trained to navigate the roads of South India, ensuring
                            that you reach your destination safely and on time. We also take pride in our fleet of
                            well-maintained vehicles, which are equipped with the latest technology and amenities to
                            make your ride as comfortable and enjoyable as possible.


                        </p>
                        <p>
                            We offer a range of drop taxi services to meet your needs, from one-way trips to round-trip
                            journeys. Our competitive pricing and transparent billing ensure that you always know what
                            you're paying for, with no hidden fees or surprises.


                        </p>
                        <p>
                            At Go2 Oneway Taxi, we are committed to providing you with the best customer service
                            possible. Our team is available 24/7 to assist you with any questions or concerns you may
                            have. We take pride in our reputation for reliability, safety, and excellent service.


                        </p>

                        <p>
                            Thank you for choosing Go2 Oneway Taxi for your transportation needs. We look forward to
                            serving you and making your journey a comfortable and memorable one.





                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>