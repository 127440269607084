<!-- BANNER START -->
<div id="booking" class="wo-mainbanner-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="wo-banner">
          <div class="wo-banner__formwrap">

            <div class="wo-bannertabs-wrap tab-content">
              <div class="wo-bannertabs-content tab-pane active fade show" id="wt-skills">

                <app-outstation></app-outstation>
              </div>
              <div class="wo-bannertabs-content tab-pane fade airport" id="wt-awards">
                <app-airport></app-airport>
              </div>
            </div>
          </div>


          <div id="wo-banner__silder" class="wo-banner__silder">
            <figure>
              <img src="assets/bg.jpg" alt="img description">
              <figcaption class="wo-slidercontent">
                <div class="wo-slidercontent__title">
                  <h1>About<span>{{settings.siteName}}</span>
                  </h1>

                </div>
                <p>At Go2 Oneway Taxi, our mission is to redefine the way you travel. We aim to provide a convenient,
                  reliable, and cost-effective alternative to traditional transportation methods. Whether you're a solo
                  traveler, a family, or a group, we want to be your first choice for getting to your destination.





                </p>
                <p>
                  We are just a call or click away from making your travel experience unforgettable. Reach out to us
                  today to book your next journey with Go2 Oneway Taxi. We look forward to being your trusted partner in
                  travel, ensuring you reach your destination comfortably and on time.






                </p>
                <ul class="wo-slidercontent__featured">
                  <li>
                    <img src="assets/images/index/vehicles-parts/img-01.jpg" alt="img description">
                    <h4>Clean Car </h4>
                  </li>
                  <li>
                    <img src="assets/money.png" alt="img description">
                    <h4>Transparent Billing
                    </h4>
                  </li>
                  <li>
                    <img src="assets/images/index/featured/img-03.png" alt="img description">
                    <h4>Reliable Service
                    </h4>
                  </li>
                  <li>
                    <img src="assets/driver.png" alt="img description">
                    <h4>Courteous Chauffeurs
                    </h4>
                  </li>
                  <li>
                    <img src="assets/toll.png" alt="img description">
                    <h4>Roadtrip Experts
                    </h4>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- BANNER END -->


<section class="about type_one">
  <div class="container">
    <div class="row">

      <div class="col-lg-6 mt-5">
        <div class="heading tp_one  icon_dark">

          <h3 class="mb-3 text-center">Drop Taxi Tariff
          </h3>
        </div>
        <div class="about_content">
          <table style="width:100%" colspan="2">
            <tbody>
              <tr>
                <th>Vehicle Type</th>
                <th>One Way</th>
                <th>Round Trip</th>
              </tr>
              <tr *ngFor="let car of cars">
                <td>{{car.carType}}</td>
                <td>Rs.{{car.oneway}}/KM</td>
                <td>Rs.{{car.roundtrip}}/KM</td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="about type_one">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="heading tp_one  icon_dark">
          <h3 class="mb-3 text-center">Points before ur travel
          </h3>

        </div>
        <div class="about_content">
          <h4> Any Sedan</h4>
          <p class="description">

            Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone will be assigned

          </p>

          <h4>Any SUV
          </h4>

          <p class="description">Innova Xylo Ertiga any one will be assigned

          </p>

          <ul class="desc">
            <li>Maximum 4 passenger capacity for Sedan</li>
            <li>Maximum 7 passenger capacity for SUV</li>

            <li>Minimum 130km coverage for one way trip</li>
            <li> Minimum 250km coverage for round trip.</li>
            <li>One day Round trip close by 11pm at ur pickup </li>
            <li>Waiting charges Rs.150 per hour in between ur trip.</li>
            <li>Food break, tea break, & rest room no waiting chrgs.</li>
            <li>Cancellation charges Rs.300 after driver arrived.</li>
            <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
            <li>Note down starting and ending km is must.</li>
            <li> Permit charges and Driver Bata is same as quoted.</li>
            <li>Toll charges will be paid thru fastag by Driver.</li>
            <li>
              Payment  transfer by online or by cash.
            </li>
            <li>
              EOT calculated running km, Toll by fastag & rest all.
            </li>
            <li> Toll fees, interstate Permit, Hill charges pet charges waiting charges (if any) are
              extra
            </li>
            <li>For Bangalore minimum 300km per day in Round trip
            </li>
            <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</section>




<!-- MAIN START -->
<main class="wo-main overflow-hidden">
  <!-- CATEGORY START -->
  <section class="wo-main-section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <h2> About {{settings.siteName}} <em> Best Drop Taxi provider </em></h2>
            </div>
            <div class="wo-sectionhead__description">
              <p>We {{settings.siteName}} started and planned the Drop Taxi Services for easier and affordable. Over the
                decade,
                we lead the pioneer of customers expectations and have been confidently making to change the dynamics
                of the outstation taxi services market in Tamil Nadu. We are proud to be the No. 1 and growing One Way
                Taxi and Return Taxi Rental Networking in Tamil Nadu and providing the best in Hire Taxi Services in
                Tamil Nadu.</p>
              <p class="mt-3">
                To ensure the customer safety, we only enrol cars with 5 or less than 5 years old. We proudly say that
                we do not have any amateur driver; all our drivers have minimum 7+ years experience. When you book our
                services everytime, you don’t need to check on the driver, or the vehicle’s documents; instead we
                check it
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="placeIds" class="container-fluid">
        <div class="wo-topvehicles-wrap">
          <div *ngFor="let place of placeIds" class="wo-topvehiclesholder">
            <div class="wo-topvehicles">
              <div class="wo-vehiclesslider">
                <div class="wo-vehiclesimgslider  owl-carousel">
                  <div *ngFor="let photo of place.photos" class="item">
                    <figure class="wo-vehicles-img">
  
                      <img
                        src="https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&&photoreference={{photo}}&key=AIzaSyBcT-UpmFnQV6UTSXANM6K14wGNhUfN9cI"
                        alt="" srcset="">
                    </figure>
                  </div>
                </div>
                <div class="wo-vehiclesimg-tags">
                  <div class="wo-tags">
                    <a class="wo-tag-featured">Featured</a>
                  </div>
                  <a class="wo-likepost"><i class="ti-heart"></i></a>
                </div>
              </div>
              <div class="wo-vehicles">
                <div class="wo-vehicles__tags">
                  <a>{{place.formatted_address}}</a>
                </div>
                <div class="wo-vehicles__title">
                  <h5><a href="vehicle-single.html">{{place.name}}</a></h5>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="wo-sectionbtns">
              <a href="#booking" class="wo-btn">Book Now</a>
            </div>
          </div>
        </div>
      </div> -->
  </section>

  <!-- CATEGORY END -->
  <!-- Spare Parts Start -->
  <section class="wo-main-section wo-spareparts-section">
    <span class="wo-spareparts-section__bg"></span>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <h2>Outstation <em> Taxi Service</em></h2>
            </div>
            <div class="wo-sectionhead__description">
              <p>Planning a weekend getaway? Our outstation cab services will help you explore the best destinations</p>
            </div>
          </div>
        </div>
        <div class="wo-spareparts">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/images/index/vehicles-parts/img-01.jpg" alt="img description">
              <h3>Outstation Taxi</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/taxi-stop.png" alt="img description">
              <h3>One way cabs</h3>
              <a routerLink="/outstation-cabs">One way cabs</a>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/images/index/vehicles-parts/img-03.jpg" alt="img description">
              <h3>Corporate Car Rental</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/airport.png" alt="img description">
              <h3>Airport Taxi</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="wo-sparepart">
                <img src="assets/train-station.png" alt="img description">
                <h3>Railway Station Taxi</h3>
                <a routerLink="/outstation-cabs">Show all</a>
              </div>
            </div> -->
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="wo-sparepart">
                <img src="assets/rental-car.png" alt="img description">
                <h3>Local Car Rentals</h3>
                <a routerLink="/outstation-cabs">Show all</a>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </section>
  <!-- Spare Parts END -->
  <!-- Vehicles Featured Start -->
  <section class="wo-main-section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <span>See The Market Trend With</span>
              <h2>Popular Outstation <em>Drop Taxi Routes</em></h2>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let place of places[0].routes" class="wo-vehiclesinfos">
            <figure class="wo-vehicles-img">
              <img src="{{place.image}}" alt="img description">
              <figcaption>
                <div class="wo-tags">
                  <a class="wo-tag-photos"><i class="ti-image"></i>25</a>
                </div>
              </figcaption>
            </figure>
            <div class="wo-vehiclesinfo">
              <div class="wo-tags">
                <a class="wo-tag-featured">Featured</a>
              </div>
              <div class="wo-vehicles__tags">
                <a>Sedan</a>
              </div>
              <div class="wo-vehiclesinfo__title">
                <h3><a href="#booking">{{place.origin}} to {{place.destination}}</a></h3>
                <address><i class="ti-location-pin"></i>{{place.origin}} to {{place.destination}}
                </address>
              </div>
              <ul class="wo-vehiclesinfo__list">
                <li><span>Car Type<em>Sedan</em></span></li>
                <li><span>Total Distance<em>{{place.distance}}</em></span></li>
                <li><span>Total Duration <em>{{place.duration}} </em> </span></li>
                <li><span>Rate Per KM<em>{{cars[0]?.oneway | currency: 'INR'}}</em></span></li>
              </ul>
            </div>
            <div class="wo-contectinfo">
              <div class="wo-contectinfo__price">
                <h4><em>One Way </em><sup>₹</sup>{{place.estimation}}</h4>
                <span>Book Chennai to Madurai Cabs at lowest price ₹6094 from {{settings.siteName}}. Get best deals for
                  {{place.origin}} to
                  {{place.destination}} car rentals. Compare from variety of car types like Sedan, Suv</span>

                <div class="wo-header-btns">
                  <a href="tel:{{settings.phoneNo}}" class="wo-contectnum"><i class="ti-mobile"></i>Call Now</a>
                  <a href="https://wa.me/{{settings.whatsapp}}" class="wo-btn wp">
                    <i class="fab fa-whatsapp"></i>Whatsapp Us</a>
                </div>

                <a href="#booking" class="wo-btn">Book Now</a>

              </div>
            </div>
          </div>

          <div class="wo-sectionbtns">
            <a routerLink="/outstation-cabs" class="wo-btn">Browse All</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="wo-fjoinnow" style="margin-top: 30px;">
    <div class="wo-fjoinnow__title">
      <h4>Book Today Get <span> 20% Discount on all Bookings</span></h4>
    </div>
    <div class="wo-fjoinnow__info">
      <p> Hire outstation and AC cab with attractive rates, clean & luxury cars, well mannered drivers &
        Transparent billing. Price starts Rs. 9/Km.</p>
    </div>
    <div class="wo-fjoinnow__btn">
      <a href="#booking" class="wo-btn">Book Now</a>
    </div>
  </div>

</main>
<!-- MAIN END -->